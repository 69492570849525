import React, { Fragment, useEffect, useState } from "react";

import moment from "moment";
import _ from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import CircleLoader from "components/General/CircleLoader/CircleLoader";
import Table from "components/General/Table";
import { NAIRA, pageCount } from "utils/appConstant";
import { ReactComponent as SearchIcon } from "assets/icons/SearchIcon/searchIcon.svg";
import useWindowDimensions from "hooks/useWindowDimensions";
import TransactionDetailsModal from "./DetailsModal";
import GiftCardAcgiftCardActivityListStore from "../store";
import { observer } from "mobx-react-lite";
import dateConstants from "utils/dateConstants";
import { ReactComponent as ArrowBack } from "assets/icons/Arrow/arrow-left-black.svg";

export const dateFilters = [
  {
    value: "today",
    label: "Today",
    start_date: dateConstants?.today,
    end_date: dateConstants?.today,
  },
  {
    value: "yesterday",
    label: "Yesterday",
    start_date: dateConstants?.yesterday,
    end_date: dateConstants?.yesterday,
  },
  {
    value: "this_week",
    label: "This Week",
    start_date: dateConstants?.startOfWeek,
    end_date: dateConstants?.endOfWeek,
  },
  {
    value: "all_time",
    label: "All Time",
    start_date: dateConstants?.firstDay,
    end_date: dateConstants?.today,
  },

  {
    value: "custom",
    label: "Custom Date",
    start_date: dateConstants?.startOfWeek,
    end_date: dateConstants?.endOfWeek,
  },
];

const Activity = () => {
  const { width, isMobile } = useWindowDimensions();
  const [currentTxnDetails, setCurrentTxnDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const { warehouse_id } = useParams();

  const searching = "";

  const {
    getGiftCardActivities,
    giftCardActivityList,
    giftCardActivityListCount,
    loadingGiftCardActivityList,
  } = GiftCardAcgiftCardActivityListStore;

  useEffect(() => {
    getGiftCardActivities({ data: { page: currentPage } });
  }, [currentPage]);

  useEffect(() => {
    console.log({ giftCardActivityList });
  }, [giftCardActivityList]);

  const columns = [
    {
      name: "Code",
      selector: "giftCardCode",
      sortable: false,
    },
    {
      name: "Description",
      selector: "description",
      sortable: false,
    },
    {
      name: "Amount",
      selector: (row) => NAIRA + " " + Number(row?.amount)?.toLocaleString(),
      sortable: false,
    },
    {
      name: "Payment method",
      selector: "paymentMethod",
      sortable: false,
    },
    {
      name: "Created At",
      selector: (row) => moment(row.createdAt).fromNow(),
      sortable: false,
    },

    {
      name: "Actions",
      selector: (row) => (
        <div className="flex justify-start items-center gap-1.5">
          <span
            className=" cursor-pointer px-4 py-1 rounded-full bg-black text-[11px] text-white"
            onClick={() => {
              setCurrentTxnDetails({
                code: row?.giftCardCode,
                modalType: "view",
              });
            }}
          >
            View
          </span>
        </div>
      ),
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => scrollToTop(), []);
  const [dateFilter, setDateFilter] = useState(dateFilters[0]);

  //   useEffect(() => {
  //     const endDate = moment(dateFilter.end_date)
  //       .add(1, "day")
  //       .format("YYYY-MM-DD");

  //     getGiftCardAcgiftCardActivityListtats({
  //       data: {
  //         endDate,
  //         startDate: moment(dateFilter.start_date).format("YYYY-MM-DD"),
  //       },
  //     });
  //   }, [dateFilter]);
  const router = useNavigate();

  return (
    <>
      <div className="h-full md:pr-4">
        <button onClick={() => router(-1)} className="scale-90 mb-2 mr-auto">
          <ArrowBack />
        </button>
        <div className="flex flex-col justify-start items-start h-full w-full gap-y-5">
          {/* <div className="flex items-center w-fit mb-3 gap-1">
            <div className="w-full sm:w-[200px]">
              <DashboardFilterDropdown
                placeholder="Filter by: "
                options={dateFilters}
                name="payout_filter"
                onClick={(e) => {
                  if (e.value === "custom") {
                    setShowDateModal(true);
                    return;
                  }
                  setDateFilter(e);
                }}
                value={dateFilter?.label}
              />
            </div>

            <div className="flex justify-start items-center w-full truncate text-base" hidden>
              {dateFilter.value === "today"
                ? moment(dateFilter.start_date).format("MMM Do, YYYY")
                : `${moment(dateFilter.start_date).format(
                    "MMM Do, YYYY"
                  )} - ${moment(dateFilter.end_date).format("MMM Do, YYYY")}`}
            </div>
          </div> */}
          <div className="flex justify-between items-center w-full mb-3 gap-1">
            {/* <div className="w-full sm:w-[45%] sm:min-w-[300px]">
              <SearchBar
                placeholder={"Search gift cards"}
                onChange={setSearchInput}
                value={searchInput}
                className="flex"
              />
            </div> */}
          </div>

          {loadingGiftCardActivityList ? (
            <CircleLoader blue />
          ) : (
            <>
              <div className="flex flex-col flex-grow justify-start items-center w-full h-full">
                {giftCardActivityList?.length > 0 ? (
                  <Table
                    data={
                      giftCardActivityList?.length
                        ? giftCardActivityList.slice(0, pageCount)
                        : []
                    }
                    columns={width >= 640 ? columns : columns.slice(0, 2)}
                    onRowClicked={(e) => {
                      setCurrentTxnDetails({
                        code: e?.giftCardCode,
                        modalType: "view",
                      });
                    }}
                    pointerOnHover
                    isLoading={loadingGiftCardActivityList}
                    pageCount={giftCardActivityListCount / pageCount}
                    onPageChange={(page) => setCurrentPage(page)}
                    currentPage={currentPage}
                    tableClassName="txn-section-table"
                    noPadding
                  />
                ) : (
                  <>
                    <div className="text-grey-text flex flex-col justify-center items-center space-y-3 h-full">
                      <SearchIcon className="stroke-current" />
                      <span>No gift card activities</span>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <TransactionDetailsModal
        active={!!currentTxnDetails}
        details={currentTxnDetails}
        toggler={() => setCurrentTxnDetails(null)}
      />
    </>
  );
};

export default observer(Activity);
