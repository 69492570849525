export const CLOSE_ICON = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.52344 16.0938L16.9312 7.68597"
        stroke="black"
        stroke-width="2.24433"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.931 16.0943L8.52319 7.68652"
        stroke="black"
        stroke-width="2.24433"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const COPY_ICON = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 4.33325H5.00004C4.82323 4.33325 4.65366 4.40349 4.52864 4.52851C4.40361 4.65354 4.33337 4.82311 4.33337 4.99992V12.9999C4.33337 13.1767 4.40361 13.3463 4.52864 13.4713C4.65366 13.5963 4.82323 13.6666 5.00004 13.6666H13C13.1769 13.6666 13.3464 13.5963 13.4714 13.4713C13.5965 13.3463 13.6667 13.1767 13.6667 12.9999V4.99992C13.6667 4.82311 13.5965 4.65354 13.4714 4.52851C13.3464 4.40349 13.1769 4.33325 13 4.33325ZM12.3334 12.3333H5.66671V5.66659H12.3334V12.3333ZM3.00004 8.99992C3.00004 9.17673 2.9298 9.3463 2.80478 9.47132C2.67975 9.59635 2.51019 9.66659 2.33337 9.66659H1.00004C0.82323 9.66659 0.65366 9.59635 0.528636 9.47132C0.403612 9.3463 0.333374 9.17673 0.333374 8.99992V0.999919C0.333374 0.823108 0.403612 0.653538 0.528636 0.528514C0.65366 0.40349 0.82323 0.333252 1.00004 0.333252H9.00004C9.17685 0.333252 9.34642 0.40349 9.47145 0.528514C9.59647 0.653538 9.66671 0.823108 9.66671 0.999919V2.33325C9.66671 2.51006 9.59647 2.67963 9.47145 2.80466C9.34642 2.92968 9.17685 2.99992 9.00004 2.99992C8.82323 2.99992 8.65366 2.92968 8.52864 2.80466C8.40361 2.67963 8.33337 2.51006 8.33337 2.33325V1.66659H1.66671V8.33325H2.33337C2.51019 8.33325 2.67975 8.40349 2.80478 8.52851C2.9298 8.65354 3.00004 8.82311 3.00004 8.99992Z"
        fill="black"
      />
    </svg>
  );
};
